.container {
    position: relative;
    width: 40%;
  }
  
  .bodySubscribe {
    padding: 3% 0 3% 0;
  }
  
  .insideBodySubscribe {
    height: auto;
    background: black;
    margin: auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background-position: unset;
    background-repeat: no-repeat;
    background-size: auto;
  }
  
  .infoContainerSub {
    width: 100%;
    height: auto;
    background: white;
    margin: auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .infoIconContainer {
    margin-bottom: 10px;
  }
  
  .subscribeLogos {
    margin: 10px 0 0 0;
  }
  
  .subscribeLogos img {
    margin: 0 10px 0 10px;
  }
  
  .subscribeHeader {
    font-size: 14px;
    font-weight: bold;
    color: #464646;
    text-align: center;
  }
  .cancelButton {
    cursor: pointer;
    color: rgb(70 70 70 / 49%);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
    text-transform: uppercase;
    margin: 20px 0 0 5px;
    text-align: center;
  }
  small {
    text-align: center;
    font-size: 12px;
    line-height: 15px;
  }
  .subscribeTextField {
    font-size: 34px;
    font-weight: bold;
    color: #464646;
    margin-bottom: 30px;
    text-align: left;
  }
  
  .cardInfo {
    display: flex;
    width: 100%;
  }
  
  .ccv {
    margin-left: 30px;
  }
  
  .cardTextField {
    background-color: #f8f8f8db;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 14px;
    color: #464646;
    margin-bottom: 20px;
  }
  .cardtextData {
    font-size: 14px;
    font-weight: bold;
    margin: 10px !important;
    color: #ffffff;
    text-align: start;
  }
  .cardCol {
    width: 50%;
  }
.sessionText{
    text-align: center;
    margin-bottom: 20px;
}
  .sessionHeading{
    font-size: 25px;
    font-weight: bold;
    margin: 10px;
    margin-left: 0;
  }
  .sessionPrice{
    font-size: 14px;
    margin: 2px;
    margin-left: 0;
  }
  .sessionTC{
    font-size: 12px;
    margin: 2px;
    margin-left: 0;
    color: white;
    text-decoration: none;
  }
  .sessionInfo{
    font-size: 11px;
    color: rgb(184, 180, 180);
    text-align: left;
  }
  .button-strip{
    display: flex;
    margin-top: 15px;
  }
  .primary-stripe{
    margin-left: 10px;
  }
  .secondary-stripe{
    margin-right: 10px;
  }
  @media screen and (max-width: 1024px) {
    .container {
        width: 60%;
    }
  }
  @media screen and (max-width: 1000px) {
    .container {
        width: 80%;
    }
    .button-strip{
        display: block;
        margin-top: 15px;
      }
    .primary-stripe{
        margin-top: 10px;
        margin-left: 0px;
      }
      .secondary-stripe{
        margin-top: 10px;
        margin-right: 0px;
      }
    
  }
  @media screen and (max-width: 500px) {
    .container {
        width: 100%;
    }
    .insideBodySubscribe {
      padding: 10px;
    }
    .cardInfo{
        display: block;
    }
    .cardCol{
        width: 100%;
    }
    .ccv{
        margin: 0;
    }
    .sessionHeading{
        font-size: 18px;
        font-weight: bold;
        margin: 10px;
        margin-left: 0;
      }
      .sessionPrice{
        font-size: 10px;
        margin: 2px;
        margin-left: 0;
      }
      .sessionTC{
        font-size: 9px;
        margin: 2px;
        margin-left: 0;
        color: white;
        text-decoration: none;
      }
      .sessionInfo{
        font-size: 10px;
        color: rgb(184, 180, 180);
        text-align: left;
      }
  }
  
  @media screen and (max-width: 500px) {
    .bodySubscribe {
      padding: 0;
    }
  }