body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Plus Jakarta Sans';
  padding-right: 0px !important;
  overflow: auto !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: 'Sora';
  src: url('/public/assets/fonts/Sora/Sora-VariableFont_wght.ttf') format('truetype'); /* Specify the path to your font file */
  font-weight: normal; /* Specify font weight */
  font-style: normal; /* Specify font style */
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/public/assets/fonts/Plus_Jakarta_Sans/PlusJakartaSans-VariableFont_wght.ttf')
    format('truetype'); /* Specify the path to your font file */
  font-weight: normal; /* Specify font weight */
  font-style: normal; /* Specify font style */
}

::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

/* Color the scrollbar thumb */
::-webkit-scrollbar-thumb {
  /* background-color: #D9D9D9;  */
  background-color: #cdd2f0;
  border-radius: 5px; /* Rounded corners of the thumb */
}

/* Color the scrollbar track */
::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the track */
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* For Firefox */
}
