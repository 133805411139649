@import './colors';

.MuiInput-root {
  &:after,
  &.MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(79, 79, 79, 0.6) !important;
  }
}

.curser-pointer {
  cursor: pointer;
}
//Global classes for Margin

.m-c-1 {
  margin: 8px;
}
.m-c-2 {
  margin: 16px;
}
.m-c-3 {
  margin: 24px;
}
.m-c-4 {
  margin: 32px;
}

.ml-c-1 {
  margin-left: 8px;
}
.ml-c-2 {
  margin-left: 16px;
}
.ml-c-3 {
  margin-left: 24px;
}
.ml-c-4 {
  margin-left: 32px;
}

.mr-c-1 {
  margin-right: 8px !important;
}
.mr-c-2 {
  margin-right: 16px !important;
}
.mr-c-3 {
  margin-right: 24px !important;
}
.mr-c-4 {
  margin-right: 32px !important;
}

.mt-c-1 {
  margin-top: 8px;
}
.mt-c-2 {
  margin-top: 16px;
}
.mt-c-3 {
  margin-top: 24px;
}
.mt-c-4 {
  margin-top: 32px;
}

.mb-c-1 {
  margin-bottom: 8px !important;
}
.mb-c-2 {
  margin-bottom: 16px !important;
}
.mb-c-3 {
  margin-bottom: 24px !important;
}
.mb-c-4 {
  margin-bottom: 32px !important;
}

.my-c-1 {
  margin-bottom: 8px;
  margin-top: 8px;
}
.my-c-2 {
  margin-bottom: 16px;
  margin-top: 16px;
}
.my-c-3 {
  margin-bottom: 24px;
  margin-top: 24px;
}
.my-c-4 {
  margin-bottom: 32px;
  margin-top: 32px;
}

.mx-c-1 {
  margin-left: 8px;
  margin-right: 8px;
}
.mx-c-2 {
  margin-left: 16px;
  margin-right: 16px;
}
.mx-c-3 {
  margin-left: 24px;
  margin-right: 24px;
}
.mx-c-4 {
  margin-left: 32px;
  margin-right: 32px;
}

.m-bottom-15{
  margin-bottom: 15px;
}
.m-bottom-35{
  margin-bottom: 35px;
}
.m-bottom-40{
  margin-bottom: 40px;
}


//Global classes for Padding

.p-c-1 {
  padding: 8px;
}
.p-c-2 {
  padding: 16px;
}
.p-c-3 {
  padding: 24px;
}
.p-c-4 {
  padding: 32px;
}
.p-c-5 {
  padding: 40px;
}

.pt-c-1 {
  padding-top: 8px;
}
.pt-c-2 {
  padding-top: 16px;
}
.pt-c-3 {
  padding-top: 24px;
}
.pt-c-4 {
  padding-top: 32px;
}
.pt-c-5 {
  padding-top: 40px;
}

.pb-c-1 {
  padding-bottom: 8px;
}
.pb-c-2 {
  padding-bottom: 16px;
}
.pb-c-3 {
  padding-bottom: 24px;
}
.pb-c-4 {
  padding-bottom: 32px;
}
.pb-c-5 {
  padding-bottom: 40px;
}

.py-c-1 {
  padding-bottom: 8px;
  padding-top: 8px;
}
.py-c-2 {
  padding-bottom: 16px;
  padding-top: 16px;
}
.py-c-3 {
  padding-bottom: 24px;
  padding-top: 24px;
}
.py-c-4 {
  padding-bottom: 32px;
  padding-top: 32px;
}
.py-c-5 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.px-c-1 {
  padding-left: 8px;
  padding-right: 8px;
}
.px-c-2 {
  padding-left: 16px;
  padding-right: 16px;
}
.px-c-3 {
  padding-left: 24px;
  padding-right: 24px;
}
.px-c-4 {
  padding-left: 32px;
  padding-right: 32px;
}
.px-c-5 {
  padding-left: 40px;
  padding-right: 40px;
}

.full-height {
  height: 100%;
}

.displayNone {
  display: none !important;
}
